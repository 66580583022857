/* ----- Variables ---------------------------------------------------------- */
:root {
  --orange: rgb(230, 125, 30);
  --teal: rgb(150, 175, 140);
  --navy: rgb(30, 30, 80);
  --blue: rgb(45, 45, 255);
  --yellow: rgb(214, 200, 0);
  --grey: rgb(228, 228, 228);
  --black: rgb(15, 15, 15);
  --white: rgb(255, 255, 255);

  --transition: all 0.23s ease-in-out 0s;
}
/* ----- End Variables ------------------------------------------------------ */

body {

}

.visible {
  visibility: visible !important;
}

.main {
  /* padding: calc(var(--space-base) / 2); */
  padding-top: var(--space-medium);
  padding-left: 1em;
  padding-right: 1em;
  min-height: calc(100vh - var(--space-menu) - calc(var(--space-medium) * 3));
}

.button {
  border: var(--border);
  border-color: inherit;
  padding: var(--space-small);
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  font-family: var(--ft-b);
  transition: var(--transition);
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
}

.button:hover {
  color: var(--orange);
  border-color: var(--orange);
}

@media all and (min-width: 500px) {
  .main {
    /* padding: calc(var(--space-base) * 1.5); */
    /* padding-top: var(--space-medium); */
  }
}

@media all and (min-width: 768px) {
  .main {
    padding-top: calc(var(--space-medium) * 1.45);
    padding-left: calc(var(--space-menu) * 2);
  }
}

@media all and (min-width: 992px) {
  .main {
    padding-right: var(--space-menu);
    padding-left: calc(var(--space-menu) * 3);
  }
}

@media all and (min-width: 1200px) {

}

/* ----- Navigation and Menu ------------------------------------------------ */
.navigation {
  /* font-family: var(--ft-b); */
}

/* Horizontal Menu */
.menu {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu li {
  flex: 1 1 auto;
  display: inline-block;
  list-style: none;
  text-align: center;
  /* writing-mode: vertical-lr; */
  /* margin-right: .5em; */
}

.menu li#books {
  background-color: var(--white);
  color: var(--black);
}

.menu li#news {
  background-color: var(--grey);
  color: var(--navy);
}

.menu li#about {
  background-color: var(--navy);
  color: var(--white);
}

.menu li a {
  color: inherit;
  text-decoration: none;
  display: block;
  padding-top: .3em;
  padding-bottom: .35em;
}

.menu ul li:hover, .menu ul li:focus {
  color: white;
  background-color: black;
}

#menu-active {
  color: white;
  background-color: black;
}

@media all and (min-width: 768px) {
  /* Vertical Menu */
  .menu {
    position: fixed;
    flex-direction: column;
    height: 100vh;
  }
  .menu li {
    writing-mode: vertical-lr;
    width: var(--space-menu);
  }
  .menu li a {
    padding: .3em .5rem .35em .5rem;
  }
}
/* ----- Navigation and Menu End -------------------------------------------- */

/* ----- Home --------------------------------------------------------------- */
.home-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(200px, auto);
}

.home-splash {
  grid-column: 1 / 13;
  background: var(--navy);
  color: var(--grey);
  cursor: default;
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  -moz-user-select: -moz-none;
  height: calc(100vh - 1.65em);
}

.home-splash img {
  height: 80%;
  max-width: 80%;
}

img.home-splash--logo {
  position: absolute;
  top: 3%;
  left: 4%;
  width: 4%;
  height: auto;
}

.home-about, .home-newsletter {
  grid-column: 1 / 13;
  background: var(--yellow);
  color: var(--black);
  padding: 1.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-about p, .home-newsletter p {
  font-family: 'CinetypeBold', sans-serif;
}

a.home-about:hover p {
  color: var(--black);
}

.home-newsletter {
  background: var(--blue);
  color: var(--white);
}

.section-head {
  text-align: center;
}

.home-books {
  grid-column: 1 / 13;
  padding: var(--space-base);
}

.home-books--list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.home-books--book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.home-books--book:first-of-type {
  grid-column: 2 / 5;
  grid-row: 2 / 5;
  z-index: 5;
}

.home-books--book:nth-child(2) {
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  z-index: 4;
}

.home-books--book:last-of-type {
  grid-column: 3 / 6;
  grid-row: 3 / 6;
  z-index: 3;
}

/* .home-books--book img {
  border: 1px solid var(--black);
} */

.home-books--image {
  display: block;
  margin: 0 auto;
  width: 90%;
  height: auto;
}

.home-news {
  background-color: var(--grey);
  grid-column: 1 / 13;
  padding: var(--space-base);
}

.home-news--list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.home-news--item {
  background-color: var(--grey);
  border: var(--border);
  font-size: calc(var(--ft-a) / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.home-news--item:first-of-type {
  grid-column: 3 / 6;
  grid-row: 3 / 6;
  z-index: 5;
}

.home-news--item:nth-child(2) {
  grid-column: 2 / 5;
  grid-row: 2 / 5;
  z-index: 4;
}

.home-news--item:last-of-type {
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  z-index: 3;
}

@media all and (min-width: 500px) {
  .home-books {
    grid-column: 1 / 7;
  }
  .books-home--book {
    width: 32%;
  }
  .home-news {
    grid-column: 7 / 13;
  }
}


@media all and (min-width: 768px) {
  img.home-splash--logo {
    right: 3%;
    left: auto;
  }
  .home-splash {
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }
}

@media all and (min-width: 992px) {
  img.home-splash--logo {
    right: 3%;
    left: auto;
  }
  .home-about, .home-newsletter {
    padding: 2.5em 4em;
  }
}

@media all and (min-width: 1200px) {
  img.home-splash--logo {
    right: 2%;
    left: auto;
    width: 3%;
  }
}

/* ----- Home End ----------------------------------------------------------- */

/* ----- About -------------------------------------------------------------- */
.about-page {
  background-color: var(--navy);
  color: var(--white);
}

.about-nav {
  /* display: none; */
  margin-bottom: var(--space-medium);
}

.about-body {
  width: 100%;
  display: inline-block;
}

.about-body section {
  margin-bottom: calc(var(--space-medium) * 1.75);
}

.kt ul.stockists,
.kt ul.libraries {
  margin-bottom: var(--space-medium);
}

.kt ul.stockists p,
.kt ul.libraries p,
.kt ul.stockists a,
.kt ul.libraries a {
  display: inline-block;
}

.kt ul.stockists p,
.kt ul.libraries p {
  padding-left: .5em;
  margin-bottom: 0;
}

@media all and (min-width: 500px) {

}

@media all and (min-width: 768px) {

}

@media all and (min-width: 992px) {
  .about-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(200px, auto);
  }
  .about-nav {
    display: inline-block;
    /* width: 20%; */
    vertical-align: top;
  }
  .about-nav li {
    margin-top: var(--space-small);
  }
  .about-body {
    /* width: 75%; */
  }
}

@media all and (min-width: 1200px) {

}
/* ----- About End ---------------------------------------------------------- */

.logo {
  position: fixed;
  left: 0;
  top: 2em;
  width: 5em;
  float: left;
}

/* ----- News --------------------------------------------------------------- */
.news-page {
  background-color: var(--grey);
  color: var(--navy);
}

.news-header {
  margin-bottom: var(--space-medium);
  color: var(--navy);
}

.news {
  display: flex;
  flex-direction: column;
}

.snake-of-time {
  width: 100%;
  height: auto;
  margin-bottom: calc(var(--space-medium) * 2);
}

.news-article {
  margin-bottom: calc(var(--space-medium) * 2);
  color: var(--navy);
  vertical-align: top;
}

.news-article--border {
  border: var(--border);
  border-color: var(--navy);
  padding: .25em;
}

.news-instagram {
  display: inline-block;
  align-self: center;
}

/* .news-article--gutter {
  width: 0;
}

.news-article--width {
  width: 100%;
} */

.news-article-title {
  margin: var(--space-small);
}

.news-article-location {
  margin: 0 var(--space-small);
}

.news-article-image {
  background-color: var(--navy);
  /* width: 60%;
  margin: 0 auto; */
  margin-bottom: var(--space-small);
}

.news-article:hover .news-article--border {
  border-color: var(--orange);
  color: var(--orange);
}

.news-article:hover .news-article-image {
  background-color: var(--orange);
}

.news-article-image img {
  width: 100%;
  filter: grayscale(100%);
  mix-blend-mode: lighten;
  display: block;
}

.news-article-image {
  margin: var(--space-small);
}

/* .news-article-image, */
.news-article-meta,
.news-article-preview {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
  margin-bottom: var(--space-small);
}

.news-article-meta p {
  display: inline-block;
}

.news-article-category {
  text-transform: uppercase;
}

.news-article .kt p {
  margin-bottom: var(--space-small);
}

@media all and (min-width: 768px) {
  /* .news {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(200px, auto);
    justify-items: center;
    align-items: center;
  } */
  .news-article {
    padding: 0 .5em;
  }
  .news-instagram {
    width: 70%;
  }
  /* .news-article--gutter {
    width: 2%;
  }
  .news-article--width { */
    /* width: 50%; */
  /* } */
  .news-article--border {
    padding: .65em;
  }
}

@media all and (min-width: 992px) {
  .news-instagram {
    width: 70%;
  }
}

@media all and (min-width: 1200px) {
  .news-instagram {
    width: 60%;
  }
}

/* ----- End News ----------------------------------------------------------- */

/* ----- Article/Event ------------------------------------------------------ */
.event {
  color: var(--navy);
}

.event-image,
.event-page-image {
  margin-bottom: var(--space-base);
}

.event-header {
  margin-bottom: var(--space-medium);
}

.event-image {
  background-color: var(--navy);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-image img,
.event-page-images img {
  width: 100%;
  filter: grayscale(100%);
  mix-blend-mode: lighten;
  border: solid black 1px;
}

.event-title,
.event-date {
  padding-bottom: var(--space-small);
}

.event-preview {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
  margin-bottom: var(--space-small);
}

.event-preview {

}

.event-page-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.event-page-images figure {
  background-color: var(--navy);
  margin-bottom: var(--space-base);
}

.event-page-images img {
  display: block;
}

.event-body {
  padding-bottom: .5rem;
}

@media all and (min-width: 500px) {

}

@media all and (min-width: 768px) {
  .event {
    color: var(--navy);
    /* display: grid;
    grid-template-columns: 2fr 1fr; */
    margin-bottom: var(--space-base);
    align-items: start;
  }
  .event-body,
  .event-image {
    margin: 0 .15em;
  }
  .event-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .event-header div,
  .event-header a.button {
    display: inline-block;
  }
  .event-header div {
    padding-right: var(--space-base);
  }
  .event-page-images {
    display: flex;
    flex-direction: row;
  }
  .event-page-images img {
    max-width: 550px;
  }
}

@media all and (min-width: 992px) {
  .event-body,
  .event-image {
    margin: 0 1em;
  }
}

@media all and (min-width: 1200px) {

}

/* ----- End Article/Event -------------------------------------------------- */

/* ----- Books -------------------------------------------------------------- */
.books-header {
  margin-bottom: var(--space-medium);
}

.products {
  display: block;
}

.product {
  margin: 2.5%;
  margin-bottom: var(--space-medium);
  /* width: 100%; */
}

.product a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-title, .product-author {
}

.product-image {
  margin-bottom: var(--space-base);
  /* transform: rotate(-2deg); */
}

.product:hover .product-image img,
.product:focus .product-image img {
  transform: rotate(-2deg);
}

.product:nth-child(odd):hover .product-image img,
.product:nth-child(odd):focus .product-image img {
  transform: rotate(1.75deg);
}

.product:nth-child(even):hover .product-image img,
.product:nth-child(even):focus .product-image img {
  transform: rotate(-2deg);
}

/* .product a:hover .product-image {
  background-color: var(--orange);
} */

/* .product a:hover .product-image img {
  filter: grayscale(100%);
  mix-blend-mode: lighten;
} */

.product-image img {
  box-shadow: var(--grey) 0 0 calc(var(--ft-a)*.75);
  width: 100%;
  /* border: solid black 1px; */
  display: block;
  margin: 0 auto;
}

@media all and (min-width: 500px) {
  .product {
    /* width: 45%; */
  }
  .product-image img {
    height: 275px;
    width: auto;
  }
}

@media all and (min-width: 768px) {
  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(200px, auto);
  }
  .product {
    margin: 0 7.5%;
    margin-bottom: var(--space-medium);
    /* width: 30%; */
  }
}

@media all and (min-width: 992px) {
  .book-page .main-wide {
    padding-right: var(--space-menu);
    padding-left: calc(var(--space-menu) * 2);
  }
  .products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
  }
}

@media all and (min-width: 1200px) {
  .product-image img {
    height: 450px;
  }
}

/* ----- End Books ---------------------------------------------------------- */

/* ----- Book --------------------------------------------------------------- */
.book-page {
  background-color: var(--white);
  color: var(--black);
}

.book-grid {
  display: block;
  padding-top: 0;
}

.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
  }

  .rslides li:first-child {
    position: relative;
    display: block;
    float: left;
  }

  .rslides img {
    display: block;
    height: auto;
    float: left;
    width: 100%;
    border: 0;
  }

  .rslides_nav {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  .rslides_nav:visited {
    color: inherit;
  }

  .transparent-btns_nav {
  z-index: 3;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 0;
  left: 0;
  display: block;
  background: #fff; /* Fix for IE6-9 */
  opacity: 0;
  filter: alpha(opacity=1);
  width: 48%;
  text-indent: -9999px;
  overflow: hidden;
  height: 96%;
  }

.transparent-btns_nav.next {
  left: auto;
  right: 0;
  }

.transparent-btns_nav:focus {
  outline: none;
  }

.book-slider,
.book-slider img {
  height: 100%;
  width: auto;
  max-width: 100%
}

.rslides li {
  height: 100%;
}

.book-cover {
  height: calc(70vh - var(--space-menu));
  width: 100%;
  /* background-color: var(--grey); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover li {
  background-size: contain
}

.book-cover img {
  box-shadow: var(--grey) 0 0 calc(var(--ft-a)*.75);
  /* transform: rotate(-2deg); */
  display: block;
  margin: 0 auto;
  /* height: auto; */
  /* width: 80%; */
  /* max-height: 100%; */
  /* max-width: 50%; */
}

.book-info {
  grid-column: 2 / 4;
  display: inline-block;
}

.book-header,
.book-author,
.book-cover {
  margin-bottom: var(--space-base);
}

.book-cover {
  margin-top: var(--space-base);
}

.book-header div,
.book-header button {
  display: block;
}

.book-details,
.snipcart-add-item {
  margin-bottom: var(--space-base);
  /* width: 80%; */
}

.book-details ul {
  margin-bottom: var(--space-small);
}

.snipcart-add-item:hover {
  color: var(--orange);
  border-color: var(--orange);
}

.book-title {
  margin-bottom: var(--space-small);
}

.snipcart-add-item {
  border: var(--border);
  padding: var(--space-small);
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  font-family: var(--ft-b);
  transition: var(--transition);
  text-align: center;
}

@media all and (min-width: 500px) {
  .book-header {
    margin-bottom: var(--space-medium);
  }
}

@media all and (min-width: 768px) {
  /* buy button top right */
  .book-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .book-header div,
  .book-header button {
    display: inline-block;
  }
  .book-header div {
    padding-right: var(--space-base);
  }
  .book-author {
    margin-bottom: 0;
  }
}

@media all and (min-width: 992px) {
  .book-cover {
    height: 79vh;
    width: 100%;
    margin-bottom: var(--space-medium);
  }
  /* .book-cover img {
    height: 85%;
    width: auto;
  } */
}
/* ----- End Book ----------------------------------------------------------- */

/* ----- Pagination --------------------------------------------------------- */
.pagination a {
  color: inherit;
}
/* ----- End Pagination ----------------------------------------------------- */

/* ----- Footer ------------------------------------------------------------- */
.footer {
  /* background-color: var(--grey); */
  /* background-color: currentColor; */
  /* color: var(--black); */
  color: currentColor;
  display: flex;
  flex-direction: column;
  /* padding: var(--space-base); */
  margin-top: calc(var(--space-medium) * 3);
  min-height: var(--space-menu);
}

.social {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer a {
  padding-right: 1em;
}

.footer svg {
  height: calc(var(--ft-a) * 1.9);
  width: auto;
  fill: currentColor;
  margin-right: 1.5em;
  margin-left: .5em;
}

/* .snipcart-checkout {
  position: fixed;
  bottom: 5px;
  right: 5px;
  padding: .5em;
  border: var(--border);
}

.cart {
  position: absolute;
} */

.snipcart-summary {
  display: inline;
}

@media all and (min-width: 768px) {
  .footer {
    padding-left: calc(var(--space-menu) * 2);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .footer a {
    padding-right: 1em;
  }

  .footer svg {
    margin-right: 1.5em;
    margin-left: .5em;
  }
}

@media all and (min-width: 992px) {
  .footer {
    padding-left: calc(var(--space-menu) * 3);
  }
}

/* ----- End Footer --------------------------------------------------------- */

/* ----- Mailchimp ---------------------------------------------------------- */
#mc_embed_signup {
  display: inline-block;
  position: relative;
  background: inherit;
  background-color: inherit;
}

#mc_embedded_subscribe_form,
 #mc_embed_signup_scroll {
  background-color: inherit;
}

#mc_embed_signup form {
  text-align: center;
}

.mc-field-group {
  display: inline-block;
}

#mc_embed_signup label {
  background-color: inherit;
  display: inline-block;
  z-index: 1;
  /* font-size: 16px; */
  /* padding-bottom: 10px; */
  /* font-weight: bold; */
}

/* positions input field horizontally */

#mc_embed_signup input.email {
  border: 0 solid #ABB0B2;
  border-bottom: 1px solid #ABB0B2;
  color: inherit;
  /* background-color: var(--grey); */
  background-color: inherit;
  box-sizing: border-box;
  /* height: 32px; */
  /* padding: 0px 0.4em; */
  display: inline-block;
  margin: 0;
  width: 250px;
  /* vertical-align: bottom; */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  transition: var(--transition);
}

#mc_embed_signup .button {
  /* font-size: 13px; */
  border: none;
  /* letter-spacing: .03em; */
  color: inherit;
  background-color: transparent;
  box-sizing: border-box;
  /* height: 32px; */
  /* line-height: 32px; */
  /* padding: 0 18px; */
  display: inline-block;
  margin: 0;
  transition: var(--transition);
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  right: -110%;
}

#mc_embed_signup .button:hover {
  color: var(--orange);
  cursor: pointer;
}

#mc_embed_signup input.email[type="email"]:not([value=""]),
#mc_embed_signup input.email[type="email"]:hover,
#mc_embed_signup input.email[type="email"]:focus,
#mc_embed_signup input.email[type="email"]:hover ~ #mc-embedded-subscribe,
#mc_embed_signup input.email[type="email"]:focus ~ #mc-embedded-subscribe {
    opacity: 1;
}

#mc_embed_signup .clear {
  display: inline-block;
}

/* positions button horizontally in line with input */

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em .5em 0em .5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}

#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em .5em .5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}

#mc_embed_signup #mce-error-response {
  display: none;
}

#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}

#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: .5em 0;
}

#mc_embed_signup input {
  font-family: inherit;
  font-size: inherit;
}

@media (max-width: 768px) {
  #mc_embed_signup input.email {
    width: 100%;
    margin-bottom: 5px;
  }
  #mc_embed_signup .clear {
    display: block;
    width: 100%
  }
  #mc_embed_signup .button {
    width: 100%;
    margin: 0;
  }
}
/* ----- End Mailchimp ------------------------------------------------------ */
