/*
  spacing
          */

:root {
  --space-small: calc(var(--baseline) * 0.5);
  --space-base: var(--baseline);
  --space-medium: calc(var(--baseline) * 2);
  --space-menu: 2.6rem;
}
