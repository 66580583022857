/* ----- Fonts -------------------------------------------------------------- */
@font-face {
  font-family: 'GT Cinetype Bold';
  src: url(../../fonts/GT-Cinetype-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GT Cinetype Bold Italic';
  src: url(../../fonts/GT-Cinetype-Bold-Italic.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT Cinetype Regular';
  src: url(../../fonts/GT-Cinetype-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT Cinetype Regular Italic';
  src: url(../../fonts/GT-Cinetype-Regular-Italic.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.bold {
  font-family: 'GT Cinetype Bold';
}

/* ----- End Fonts ---------------------------------------------------------- */

:root {
  --ft-b: "GT Cinetype Bold", sans-serif;
  --ft-bi: "GT Cinetype Bold Italic", sans-serif;
  --ft-r: "GT Cinetype Regular", sans-serif;
  --ft-ri: "GT Cinetype Regular Italic", sans-serif;
  --ft-a: 18px;
  --ft-lh: 1;
	--baseline: calc(var(--ft-a) * 1.2);
  --border: 1px solid #222;
}

html {
  font-family: var(--ft-r);
  font-size: var(--ft-a);
  line-height: calc(var(--ft-lh) * 1.35);
}

em {
  font-style: italic;
}

h1 {
  /* font-size: calc(var(--ft-a) * 1.5); */
}

h1,
h2 {
  font-family: var(--ft-b);
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--orange);
}

.ft-sm {
  font-size: calc(var(--ft-a) * 1);
  line-height: calc(var(--ft-a) * 1);
}

.ft-rg {
  font-size: var(--ft-a);
  line-height: var(--ft-lh);
}

.ft-lg {
  font-size: calc(var(--ft-a) * 1.5);
  line-height: calc(var(--ft-a) * 1.5);
}

.ft-b {
  font-family: var(--ft-b);
}

@media all and (min-width: 500px) {
  html {
    font-size: calc(var(--ft-a) * 1.5);
  }
  .ft-sm {
    font-size: calc(var(--ft-a) * 0.9);
    line-height: calc(var(--ft-a) * 0.9);
  }

  .ft-lg {
    font-size: calc(var(--ft-a) * 2.5);
    line-height: calc(var(--ft-a) * 2.5);
  }
}

/* ----- Kirbytext ---------------------------------------------------------- */
.kt p {
  margin-bottom: var(--space-base);
}

.kt h1,
.kt h2 {
  font-family: var(--ft-b);
  margin-bottom: var(--space-base);
}

.kt a {
  text-decoration: underline;
}

.kt > ul,
.kt > ol {
  margin-bottom: var(--space-base);
}

.kt ul {
  list-style: none;
  font-size: .85em;
}

.kt ul li {
  display: table-row;
  padding: 0 0 0 0.75rem;
}

.kt ul li:before {
  display: table-cell;
  text-align: right;
  padding: 0 var(--space-small) 0 0;
  content: '\2014';
}

pre, code {
  white-space: pre;
  white-space: pre-wrap;
  font-family: 'SF Mono';
  font-size: 95%;
}

.kt pre {
  border: 1px solid red;
  display: block;
  padding: var(--space-small);
}

.ls--n:before {
  padding: 0;
  content: '';
}

.kt ol {
  list-style: none;
  display: table;
}

.kt ol li {
  display: table-row;
  counter-increment: number-list;
}

.kt ol li:before {
  content: counter(number-list) ".";
  display: table-cell;
  text-align: right;
  margin: 0 0 0 -2rem;
  width: 2rem;
  padding: 0 var(--space-small) 0 0;
}

@media all and (min-width: 500px) {

}
/* ----- End Kirbytext ------------------------------------------------------ */
