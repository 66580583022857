.snip-layout {
  background: rgba(255, 255, 255, 0.35);
  color: var(--navy);
  font-family: inherit;
  font-size: 14px;
}

.snip-layout .snip-header,
.snip-layout .snip-footer,
.snip-layout .snip-header__total {
  background: var(--grey);
}

.snip-layout .snip-btn--highlight {
  background: var(--orange);
  color: var(--white);
}

.snip-layout .snip-btn--highlight:hover,
.snip-layout .snip-quantity-trigger__btn:hover,
.snip-layout .snip-loader--bar:before {
  background-color: var(--orange);
}
